import "./IconLink.css";

const IconLink = (props) => {
    const {
        caption,
        url,
        icon,
        name
    } = props;

    return (
        <div className="icon-link" key={props.id}>
            <img src={icon} alt={`${name} icon`} />

            <a href={url} target="_blank" rel="noreferrer noopener">{caption}</a>
        </div>
    );
}

export default IconLink;