import ReactGA from "react-ga4";

import "./Button.css";

const Button = (props) => {
    const {
        caption,
        color,
        onClick,
        url,
        icon,
        type
    } = props;

    const sendGAEvent = () => {
        ReactGA.event({
            category: "button",
            action: `${caption} (${url})`,
            label: `${caption} (${url})`
        });
    }

    if (url) {
        return (
            <a href={url || "#"} target="_blank" rel="noopener noreferrer" className="anchor-button-container">
                <button className="button" style={{ backgroundColor: color }} onClick={sendGAEvent} type={type}>
                    {caption}
                    {icon || null}
                </button>
            </a>
        )
    } else {
        return (
            <button className="button" style={{ backgroundColor: color }} type={type} onClick={onClick || (() => {})}>
                {caption}
                {icon || null}
            </button>
        );
    }
};

export default Button;