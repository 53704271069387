import { createBrowserRouter, RouterProvider } from "react-router";
import ReactGA from "react-ga4";
import './App.css';

import Home from "./pages/Home";
import Teaching from "./pages/Teaching";
import Projects from "./pages/Projects";

const trackingId = "G-SJWYYXW2TN";
ReactGA.initialize(trackingId);

const router = createBrowserRouter([
    {
        index: true,
        element: <Home />
    },
    {
        path: 'projects',
        element: <Projects />
    },
    {
        path: 'teaching',
        element: <Teaching />
    }
])

const App = () => {
    return (
        <RouterProvider router={router} />
    );
};

export default App;
