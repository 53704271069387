import { Link } from "react-router";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import "./Footer.css";

import Constants from "../App.config";

import FancyLink from "../components/FancyLink";
import Section from "../components/Section";

const defaultRoutes = {
    teaching: {
        label: "Teaching",
        type: "route",
        path: "/teaching"
    },

    projects: {
        label: "Projects",
        type: "route",
        path: "/projects"
    }
};

const Footer = (props) => {
    const {
        otherCopyrights,
        routes
    } = props;

    const renderRoutes = () => routes.map((route) => {
        const routeData = defaultRoutes[route];

        switch (routeData.type) {
            case "route":
                return (
                    <Link to={routeData.path} key={`lnk-${route}`}>
                        <p>{routeData.label}</p>
                        <ArrowForwardIcon classes={{ root: "forward-icon" }} />
                    </Link>
                );
            case "url":
                return (
                    <a href={routeData.path} key={`lnk-${route}`} target="_blank" rel="noopener noreferrer">
                        <p>{routeData.label}</p>
                        <ArrowForwardIcon classes={{ root: "launch-icon" }} />
                    </a>
                );
            default:
                return null;
        }
    });

    return (
        <Section
            textColor="var(--color-secondary-lighter)"
            backgroundColor="var(--color-primary-darkest)"
            flexDirection="column"
            contentContainerStyle={{ alignItems: "center" }}
        >
            <div className="further-links">
                {renderRoutes()}
            </div>
            
            <FancyLink containsEmoji onClick={() => window.scrollTo(0, 0)}>{Constants.Brand.Footer}</FancyLink>

            <p className="footer-text">
                {Constants.Copyrights.default}<br/>
                {otherCopyrights}
            </p>
        </Section>
    );
};

export default Footer;