import "./Section.css";

export const Fragment = (props) => {
    const { side, classes } = props;

    return (
        <div className={`section-fragment section-fragment-${side} ${classes || ""}`}>
            {props.children}
        </div>
    );
}

const Section = (props) => {
    const {
        textColor,
        backgroundColor,
        flexDirection,
        style,
        contentContainerStyle,
        id
    } = props;

    return (
        <section className="child-container" style={{
            backgroundColor,
            color: textColor,
            ...style
        }} {...id ? { id } : null}>
            <div className="section" style={{
                flexDirection,
                ...contentContainerStyle
            }}>
                {props.children}
            </div>
        </section>
    );
};

export default Section;