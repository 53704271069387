import "./FancyLink.css";

const paddings = {
    default: "0.25rem 1rem",
    emoji: "0.25rem 1rem 0.4rem 1rem"
};

const FancyLink = (props) => {
    const {
        containsEmoji,
        url,
        onClick
    } = props;

    return (
        <a
            href={url}
            className="fancy-link"
            style={{
                padding: containsEmoji ? paddings.emoji : paddings.default
            }}
            {...onClick ? { onClick } : null}
        >
            {props.children}
        </a>
    );
};

export default FancyLink;