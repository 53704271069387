import logo from "./assets/images/px-logo.svg";
import githubIcon from "./assets/images/github-icon.svg";
import linkedinIcon from "./assets/images/linkedin-icon.svg";
import emailIcon from "./assets/images/email-icon.svg";
import faceoffLogo from "./assets/images/faceoff-logo.png";
import faceoffBackground from "./assets/images/faceoff-bg.png";
import whyrvrcLogo from "./assets/images/whyrvrc-logo.svg";
import whyrvrcBackground from "./assets/images/whyrvrc-bg.png";
import cloudJumpersLogo from "./assets/images/cloud-jumpers-logo.gif";
import cloudJumpersBackground from "./assets/images/cloud-jumpers-bg.png";
import bloodconnectLogo  from "./assets/images/bloodconnect-logo.svg";
import bloodconnectBackground from "./assets/images/bloodconnect-bg.png";
import keeptrackLogo from "./assets/images/keeptrack-logo.svg";
import keeptrackBackground from "./assets/images/keeptrack-bg.png";
import chemakersLogo from "./assets/images/chemakers-logo.svg";
import chemakersBackground from "./assets/images/chemakers-bg.png";
import strawyBackground from "./assets/images/strawy-bg.png";
import cs1010sBackground from "./assets/images/cs1010s-bg.jpg";
import cs3216Background from "./assets/images/cs3216-bg.jpg";
import { Link } from "react-router";

const config = {
    Contact: [
        {
            platform: "GitHub",
            icon: githubIcon,
            url: "https://github.com/purfectliterature",
            label: "github.com/purfectliterature"
        },
        {
            platform: "LinkedIn",
            icon: linkedinIcon,
            url: "https://linkedin.com/in/phillmont",
            label: "linkedin.com/in/phillmont"
        },
        {
            platform: "Email",
            icon: emailIcon,
            url: "mailto:phillmont@u.nus.edu?subject=Hi, Phillmont!&body=Dear Phillmont,",
            label: "phillmont@u.nus.edu"
        }
    ],

    Profile: {
        FirstName: "Phillmont",
        LastName: "Muktar",
        DisplayName: "Phillmont Muktar",
        Email: "phillmont@u.nus.edu",
        Introduction: {
            title: "Halo 👋!",
            intro: <>
                My name is <strong>Phillmont Muktar</strong>, a Software Engineer at the <a href="https://aicet.comp.nus.edu.sg" target="_blank" rel="noreferrer noopener">AI Centre for Educational Technologies</a>, a research centre at the <a href="https://nus.edu.sg" target="_blank" rel="noreferrer noopener">National University of Singapore (NUS)</a>. 
                I graduated from NUS, studied <strong>Computer Science</strong> and specialised in <strong>Software Engineering</strong>.

                <br/><br/>
                I&apos;m building <a href="https://cikgo.com" target="_blank" rel="noreferrer noopener">Cikgo</a>, a personalised learning platform that enables teachers to deliver good, personalised learning experiences at scale, inspired by real-life teacher-student interactions, supercharged by AI.

                <br/><br/>
                I&apos;m also developing <a href="https://coursemology.org" target="_blank" rel="noreferrer noopener">Coursemology.org</a>, an open-source, gamified learning management system, used by 50 MOE schools and 34 NUS courses, with over 300 instructors and 22,000 students to date.

                <br/><br/>
                Oh, I also <Link to="/teaching">teach programming and advanced software engineering</Link> at the Department of Computer Science at the School of Computing at NUS!

                <br/><br/>
                I aspire to use my skills to <strong>solve real-world problems</strong> and drive the advancements in science, engineering, and technology.
                I&apos;m highly passionate in the fields of <strong>software engineering</strong> and <strong>human-computer interaction</strong>.
            </>
        },
        Projects: {
            title: "Projects",
            intro: <>
                I find it magical to be able to create something. These projects are avenues for me to not only improve and apply my skills, but also collaborate with talented like-minded geniuses and develop solutions to 
                real-world problems and tap into the lives of many people. I hope that one day I will be able to be a part of that movement.
            </>
        },
        Teaching: {
            title: "Teaching",
            intro: <>
                I believe that teaching is the highest form of understanding. My teaching experiences have improved my professionalism and make me appreciate the craftmanship of a successful learning experience for the students.
                Teaching&mdash;being able to elevate one's skills to a higher level&mdash;is definitely an amazing superpower that I would like to continuously develop and hone. These are my services at the Department of Computer Science at the School of Computing, National University of Singapore.
            </>
        },
    },

    Brand: {
        Title: "Phillmont",
        Logo: logo,
        Footer: "Made with ❤️ by Phillmont"
    },

    Tagline: {
        Line1: "I love software.",
        Line2: "I love building it.",
        Line3: "I love your ideas.",
        Line4: "Let's talk about it."
    },

    Showcase: ["whyrvrc", "cloudJumpers", "faceoff", "bloodconnect", "keeptrack", "chemakers"],
    ProjectsList: ["mercapto", "antimony", "panoptoBookmarklet", "gkisumutmedan"],
    OtherProjectsList: ["strawy"],

    Projects: {
        cloudJumpers: {
            title: "Cloud Jumpers",
            subtitle: "A multiplayer platformer game for iPadOS.",
            description: <>This game is a fulfillment of <a href="https://cs3217.github.io/cs3217-docs/" target="_blank" rel="noopener noreferrer">CS3217, an advanced software engineering course at NUS</a>. I designed and implemented the entire game's system architecture from scratch (that's the challenge in this course!), and created all the sprites. It <a href="https://uvents.nus.edu.sg/event/20th-steps/module/CS3217/project/6" target="_blank" rel="noopener noreferrer">won first place in the 20th STePS</a>.</>,
            logo: cloudJumpersLogo,
            ctaCaption: "See why it's an engineering marvel",
            ctaUrl: "https://github.com/CloudJumpers/CloudJumpers",
            isCtaExternal: true,
            role: <>Software Architect<br/>Software Engineer<br/>Asset Artist</>,
            color: "#ff8bca",
            colorLighter: "#ffeffb",
            colorDarker: "#ff60be",
            illustration: cloudJumpersBackground
        },

        whyrvrc: {
            title: "whyrvrc.sg",
            subtitle: "Ridge View Residential College's showcase website for prospective students.",
            description: <>This website is an online prospectus for <a href="https://rvrc.nus.edu.sg/" target="_blank" rel="noopener noreferrer">Ridge View Residential College</a>, primarily used during NUS Open Day. I oversaw the design from ideation with the theme of "The potential to be what you want to be." in 2021, prototyping and assets creation, to the final engineering and deployment of the website.</>,
            logo: whyrvrcLogo,
            ctaCaption: "Experience the ridge",
            ctaUrl: "https://whyrvrc.by.phillmont.dev/",
            isCtaExternal: true,
            role: <>Brand Identity Designer<br/>Full-stack Software Engineer</>,
            color: "#9C6BBD",
            colorLighter: "#F5F5F5",
            colorDarker: "#532C6D",
            illustration: whyrvrcBackground
        },

        faceoff: {
            title: "FaceOff",
            subtitle: "An online multiplayer facial expressions party pack mobile game.",
            logo: faceoffLogo,
            description: <>FaceOff is an online multiplayer party pack game which uses players' faces as controllers. It comes with 3 party games: Detective Face, FaCard, and FacePhone. It was my CS3216 final project, and it <a href="https://isteps.comp.nus.edu.sg/event/17th-steps/result" target="_blank" rel="noopener noreferrer">won first place in the 17th STePS</a> and was <a href="https://www.youtube.com/watch?v=8vOPJO4wYxQ" target="_blank" rel="noopener noreferrer">featured in SoC Open Day 2021</a>.<br/><br/>P.S. The TURN server is no longer maintained, so playing over internet may no longer be possible.</>,
            ctaCaption: "Play now",
            ctaUrl: "https://faceoff.fun/",
            isCtaExternal: true,
            role: <>User Interface Designer<br/>Front-end Engineer</>,
            color: "#5A95BF",
            colorLighter: "#D2F4FF",
            colorDarker: "#1D4975",
            illustration: faceoffBackground
        },

        bloodconnect: {
            title: "BloodConnect",
            subtitle: "A platform connecting blood donors and donees.",
            logo: bloodconnectLogo,
            description: "BloodConnect aims to reduce waiting time for blood donations in emergencies and encourage young donors by introducing a reliable, relevant, digital platform, allowing blood seekers to make requests, and donors to respond to them. BloodConnect is an NUS Orbital 2020 project, and is built with React Native and Firebase.",
            ctaCaption: "Read more",
            ctaUrl: "https://bloodwork-nus.github.io/bloodconnect/docs/milestone1",
            isCtaExternal: true,
            role: "Full-stack Developer",
            color: "#DE2A6F",
            colorLighter: "#FFE2ED",
            colorDarker: "#B6074A",
            illustration: bloodconnectBackground
        },

        keeptrack: {
            title: "KeepTrack",
            subtitle: "Makes checking in and out of SafeEntry faster and easier.",
            logo: keeptrackLogo,
            description: "KeepTrack is my initiative to ease the use of SafeEntry by allowing users to save SafeEntry links and check-in and -out without needing to scan the QR code again.",
            ctaCaption: "Read more",
            ctaUrl: "https://purfectliterature.github.io/keeptrack/docs/",
            isCtaExternal: true,
            role: "The dude who made the whole thing :)",
            color: "#F7B416",
            colorLighter: "#FFF4D9",
            colorDarker: "#F18820",
            illustration: keeptrackBackground
        },

        chemakers: {
            title: "CheMakers",
            subtitle: "A board game designed to teach organic chemistry through higher-order thinking.",
            logo: chemakersLogo,
            description: "I designed the game and their components, and CheMakers Answers, a mobile-only web app to scan QR codes on the question cards and view their answers.",
            ctaCaption: "View CheMakers Answers on GitHub",
            ctaUrl: "https://github.com/purfectliterature/chemakers-ans",
            isCtaExternal: true,
            role: "Product Design Lead",
            color: "#007AFF",
            colorLighter: "#D4E9FF",
            colorDarker: "#0059BB",
            illustration: chemakersBackground
        },

        mercapto: {
            title: "Mercapto",
            subtitle: "Automating plagiarism checking from submissions to code difference.",
            description: "Mercapto is suite of Python scripts to automate the plagiarism checking process for CS1010S, a module which I am teaching. It automates the processes of downloading students' submissions from Coursemology.org and uploading them to Stanford's Measure of Software Similarity (MOSS).",
            ctaCaption: "View Mercapto on GitHub",
            ctaUrl: "https://github.com/purfectliterature/Mercaptoethanol",
            isCtaExternal: true,
            role: "Developer"
        },

        antimony: {
            title: "SensiRC",
            subtitle: "A customer records management system.",
            description: "SensiRC is a customer records management application built to assist salespersons in keeping track of products offered to various retail shop owners. It was developed as a proof-of-concept for PT Sensi Indonesia, a health-care products manufacturer headquartered in Indonesia. The front-end was built for Android devices with Android Studio and back-end was built with Node.js and MySQL.",
            ctaCaption: "View cleaned generic source",
            ctaUrl: "https://github.com/purfectliterature/GenericAntimony",
            isCtaExternal: true,
            role: "Freelance Full-stack Developer"
        },

        panoptoBookmarklet: {
            title: "PanoptoBookmarklet",
            description: "PanoptoBookmarklet is a JavaScript bookmarklet to download web lecture videos from Panopto, a platform used by the National University of Singapore to deliver live and recorded lectures.",
            ctaCaption: "Read more at GitHub",
            ctaUrl: "https://github.com/purfectliterature/PanoptoBookmarklet/blob/master/README.md",
            isCtaExternal: true,
            role: "Developer"
        },

        gkisumutmedan: {
            title: "GKI Sumut Medan",
            description: <>
                Together with <a href="https://stanley.id" target="_blank" rel="noopener noreferrer">Stanley Ang</a>, I co-developed a feed reader app for the Indonesian Christian Church of North Sumatra in Medan (GKI Sumut Medan) to deliver daily devotions, events schedules, news, and book online consultations with reverends for Android with Android Studio.
            </>,
            role: "Co-developer (Freelance)"
        },

        strawy: {
            title: "Project Strawy",
            subtitle: "Investigating edible straws to reduce plastics waste.",
            description: "Project Strawy is a research project to investigate the feasibility of manufacturing edible straws as an alternative to plastic straws. Prototypes were created and tested with respondents from students at the National University of Singapore.",
            ctaCaption: "View poster",
            ctaUrl: "https://drive.google.com/file/d/15xKE6J7IFcU33w_PgHe4C2bpB9JK7ku8/view?usp=sharing",
            isCtaExternal: true,
            role: <>Project Leader (Technology)<br/>Evaluator and Outreach</>,
            illustration: strawyBackground
        }
    },

    Teachings: [
        {
            code: "CS3216",
            title: "Software Product Engineering for Digital Markets",
            ctaCaption: "Learn more",
            ctaUrl: "https://cs3216.com/",
            isCtaExternal: true,
            role: "Teaching Assistant",
            illustration: cs3216Background,
            description: "CS3216 is an advanced software engineering module where students from diverse disciplines learn how to learn and develop software products as solutions to real-world problems.",
            coordinator: <>A/P Soo Yuen Jien</>,
            period: <>2024/2025 Semester 1<br/>2023/2024 Semester 1<br/>2021/2022 Semester 1</>
        },
        {
            code: "CS3217",
            title: "Software Engineering on Modern Application Platforms",
            ctaCaption: "Learn more",
            ctaUrl: "https://cs3217.github.io/cs3217-docs/",
            isCtaExternal: true,
            role: <>Head Teaching Assistant (2024)<br/>Teaching Assistant (2023)</>,
            description: "CS3217 is an advanced software engineering module where students learn the building core software engineering skills, such as enterprise architecture patterns, design patterns, test-driven development, and other core principles that build sound, scalable modern applications.",
            coordinator: <>Dr Leong Wai Kay</>,
            period: <>2023/2024 Semester 2<br/>2022/2023 Semester 2</>
        },
        {
            code: "CS1010S, CS1010A",
            title: "Programming Methodology",
            ctaCaption: "Read more",
            ctaUrl: "https://nusmods.com/modules/CS1010S/programming-methodology",
            isCtaExternal: true,
            role: <>Recitation Instructor (2023)<br/>Senior Teaching Assistant (2020&ndash;2021)<br/>Head Teaching Assistant (2019)</>,
            illustration: cs1010sBackground,
            description: "CS1010S is a computational thinking module, specially tailored for Science and Business Analytics students, which introduces fundamental problem-solving and programming concepts using Python. In August 2023, it was recoded to CS1010A.",
            coordinator: <>Dr Leong Wai Kay<br/>A/P Leong Wing Lup, Ben</>,
            period: <>2023/2024 Semester 1<br/>2021/2022 Semester 1<br/>2020/2021 Semesters 1 & 2<br/>2019/2020 Semesters 1 & 2</>
        },
        {
            code: "CS1010E",
            title: "Programming Methodology",
            ctaCaption: "Read more",
            ctaUrl: "https://nusmods.com/modules/CS1010E/programming-methodology",
            isCtaExternal: true,
            role: "Module Manager",
            description: "CS1010E is a computational thinking module, specially tailored for Engineering students, which introduces fundamental problem-solving and programming concepts using Python.",
            coordinator: <>A/P Khoo Siau Cheng</>,
            period: <>2021/2022 Special Term II<br/>2020/2021 Special Term II</>
        },
        {
            code: "CP2106",
            title: "Independent Software Development Project (Orbital)",
            ctaCaption: "Learn more",
            ctaUrl: "https://orbital.comp.nus.edu.sg/",
            isCtaExternal: true,
            role: <>Senior Advisor<br/>Senior Tutor</>,
            description: "Orbital is NUS School of Computing's freshmen summer self-directed, independent coursework which gives students the opportunity to pick up software development skills on their own, using sources on the web.",
            coordinator: <>Dr Zhao Jin</>,
            period: <>2020/2021 Special Terms I & II</>
        },
        {
            code: "CS2030, CS2030S",
            title: "Programming Methodology II",
            ctaCaption: "Read more",
            ctaUrl: "https://nusmods.com/modules/CS2030/programming-methodology-ii",
            isCtaExternal: true,
            role: "Teaching Assistant",
            description: "CS2030 is a continuation of CS1010-flavoured modules, which explores the paradigms of object-oriented and functional programming, and object-oriented software design principles, including, among others, inheritance, polymorphism, and composition.",
            coordinator: <>Dr Chia Wai Kit, Henry<br/>A/P Sim Mong Cheng, Terence</>,
            period: <>2020/2021 Semester 1</>
        },
        {
            code: "Python Programming for the Public Service",
            role: "Instructor",
            description: "Python Programming for the Public Service is a module which introduces the fundamental problem-solving, programming, and data analysis concepts using Python, specifically designed for the public servants.",
            coordinator: "A/P Leong Wing Lup, Ben",
            period: <>January&ndash;November 2020</>
        }
    ],

    Titles: {
        Home: "Phillmont × Software",
        Projects: "Projects | Phillmont × Software",
        Teaching: "Teaching | Phillmont × Software"
    },

    Copyrights: {
        default: `Copyright © ${new Date().getFullYear()} Phillmont Muktar. All rights reserved.`,
        home: <>
            Graphic of person scanning with phone in the KeepTrack feature is created by <a className="boring-link" href="https://www.freepik.com/free-photos-vectors/people" target="_blank" rel="noreferrer noopener">pch.vector</a> from <a className="boring-link" href="https://www.freepik.com" target="_blank" rel="noreferrer noopener">www.freepik.com</a>.
        </>,
        projects: <>
            Graphic of person scanning with phone in the KeepTrack feature is created by <a className="boring-link" href="https://www.freepik.com/free-photos-vectors/people" target="_blank" rel="noreferrer noopener">pch.vector</a> from <a className="boring-link" href="https://www.freepik.com" target="_blank" rel="noreferrer noopener">www.freepik.com</a>.
        </>
    }
};

export default config;