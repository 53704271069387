import { useEffect } from 'react';
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";
import LaunchIcon from "@material-ui/icons/Launch";
import "./Projects.css";

import Constants from "../App.config";

import Header from '../components/Header';
import ContactMe from '../components/ContactMe';
import Footer from '../components/Footer';
import Section, { Fragment } from '../components/Section';
import BlobItem from "../components/BlobItem";
import { renderShowcaseItems } from "./Home";
import { ScrollRestoration } from 'react-router';

const renderProjectItems = () => Constants.ProjectsList.map((projectId) => {
    const project = Constants.Projects[projectId];

    return (
        <BlobItem
            key={`prj-${projectId}`}
            title={project.title}
            subtitle={project.subtitle}
            logo={project.logo}
            description={project.description}
            ctaCaption={project.ctaCaption}
            ctaUrl={project.ctaUrl}
            ctaIcon={project.isCtaExternal ? <LaunchIcon /> : null}
            color="#6EA379"
            colorLighter="rgba(0,0,0,0.07)"
            colorDarker="#144840"
            illustration={project.illustration}
            metadata={[
                {
                    title: "MY ROLE",
                    value: project.role
                }
            ]}
        />
    );
});

const renderOtherProjects = () => Constants.OtherProjectsList.map((projectId) => {
    const project = Constants.Projects[projectId];

    return (
        <BlobItem
            key={`prj-${projectId}`}
            title={project.title}
            subtitle={project.subtitle}
            logo={project.logo}
            description={project.description}
            ctaCaption={project.ctaCaption}
            ctaUrl={project.ctaUrl}
            ctaIcon={project.isCtaExternal ? <LaunchIcon /> : null}
            color="#9000FE"
            colorLighter="#F0D1FF"
            colorDarker="#5100D0"
            illustration={project.illustration}
            metadata={[
                {
                    title: "MY ROLE",
                    value: project.role
                }
            ]}
        />
    );
});

const Projects = (props) => {
    useEffect(() => { ReactGA.send({ hitType: "pageview", page: "/projects", title: "Projects" }); }, []);
    
    return (<>
        <ScrollRestoration />

        <Helmet>
            <title>{Constants.Titles.Projects}</title>
        </Helmet>

        <Header focus="projects" />

        <Section
            textColor="var(--color-primary-darker)"
            backgroundColor="white"
            flexDirection="row"
            style={{ paddingTop: "8rem" }}
        >
            <Fragment side="left">
                <h2>{Constants.Profile.Projects.title}</h2>
            </Fragment>

            <Fragment side="right">
                <p className="body-text">{Constants.Profile.Projects.intro}</p>
            </Fragment>
        </Section>

        <div className="showcase" style={{ paddingTop: 0 }}>
            {renderShowcaseItems()}
            {renderProjectItems()}
        </div>

        <div className="showcase">
            <h2>Other subdisciplinary projects</h2>
            {renderOtherProjects()}
        </div>

        <ContactMe />

        <Footer
            routes={["teaching"]}
            otherCopyrights={Constants.Copyrights.projects}
        />
    </>);
};

export default Projects;