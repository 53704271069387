import { useEffect, useState } from 'react';
import { Link } from "react-router";
import CloseIcon from "@material-ui/icons/Close";
import MenuIcon from "@material-ui/icons/Menu";
import "./Header.css";

import Constants from "../App.config";

const renderContactButtons = () => Constants.Contact.map((platform) => (
    <a href={platform.url} rel="noopener noreferrer" target="_blank" key={`cb-${platform.platform}`}>
        <img src={platform.icon} alt={`${platform.platform} icon`} />
    </a>
));

const Header = (props) => {
    const {
        focus
    } = props;

    const [opaque, setOpaque] = useState(false);
    const [menuShowed, setMenuShowed] = useState(false);

    const showMenu = () => {
        document.body.style.overflow = "hidden";
        setMenuShowed(true);
    }

    const closeMenu = () => {
        document.body.style.overflow = "scroll";
        setMenuShowed(false);
    }

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.pageYOffset > 0) {
                setOpaque(true);
            } else {
                setOpaque(false);
            }
        });
    }, []);

    return (
        <>
            <div className={`menu ${menuShowed ? "visible" : ""}`}>
                <div className="menu-contents">
                    <Link to="/projects" className="link" onClick={closeMenu}>Projects</Link>
                    <Link to="/teaching" className="link" onClick={closeMenu}>Teaching</Link>
                    <a className="link" href="#contact-me" onClick={closeMenu}>Contact me</a>

                    <div className="menu-contact-icons">
                        {renderContactButtons()}
                    </div>
                </div>

                <button className="close-button" onClick={closeMenu}><CloseIcon fontSize="large" classes={{ root: "close" }} /></button>
            </div>

            <div className="header-container">
                <div className={`header ${opaque ? "header-opaque" : ""}`}>
                    <div className="fragment fragment-left">
                        <Link to="/" className={`brand ${opaque ? "colored-brand" : ""}`}>{Constants.Brand.Title}</Link>
                    </div>

                    <Link to="/" className="logo-container">
                        <img className="logo" src={Constants.Brand.Logo} alt="Logo" />
                    </Link>

                    <div className="fragment fragment-right">
                        <Link to="/projects" className={`link ${focus === "projects" ? "focused" : ""}`}>Projects</Link>
                        <Link to="/teaching" className={`link ${focus === "teaching" ? "focused" : ""}`}>Teaching</Link>
                        <button className="hamburger" onClick={showMenu}><MenuIcon fontSize="large" /></button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Header;