import { useState } from 'react';
import SendIcon from "@material-ui/icons/Send";
import "./ContactMe.css";

import Constants from "../App.config";

import Button from "../components/Button";
import TextBox from "../components/TextBox";
import IconLink from "../components/IconLink";
import Section, { Fragment } from "../components/Section";

const renderContactLinks = () => Constants.Contact.map((platform) => (
    <IconLink name={platform.platform} caption={platform.label} url={platform.url} icon={platform.icon} key={`cl-${platform.platform}`} />
));

const ContactMe = (props) => {
    const {
        headline
    } = props;

    const [contactName, setContactName] = useState("");
    const [contactEmail, setContactEmail] = useState("");
    const [contactMessage, setContactMessage] = useState("");

    return (
        <Section
            id="contact-me"
            textColor="var(--color-primary-darkest)"
            backgroundColor="var(--color-secondary-lighter)"
            flexDirection="row"
        >
            <Fragment side="left">
                <h2>{headline || "What should I do next?"}</h2>

                <div className="connect">
                    <h3>Connect with me,</h3>

                    {renderContactLinks()}
                </div>
            </Fragment>

            <Fragment side="right" classes="contact">
                <h3>...or, drop me a message!</h3>

                <form className="contact-form" name="contact" method="post">
                    <input type="hidden" name="form-name" value="contact" />

                    <div className="horizontal-textbox">
                        <TextBox
                            id="name"
                            label="Name"
                            placeholder="What's your name?"
                            onChange={setContactName}
                            value={contactName}
                        />

                        <TextBox
                            id="email"
                            type="email"
                            label="Email address"
                            placeholder="What's your email?"
                            onChange={setContactEmail}
                            value={contactEmail}
                        />
                    </div>

                    <TextBox
                        id="message"
                        label="Message"
                        placeholder="Any messages for me?"
                        onChange={setContactMessage}
                        value={contactMessage}
                        multiline={true}
                    />

                    <Button caption="Send" icon={<SendIcon />} type="submit" />
                </form>
            </Fragment>
        </Section>
    );
}

export default ContactMe;